var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-2 mt-2",attrs:{"tile":""}},[_c('CardTitle',{attrs:{"title":"Tasks","icon":"pi-task"}},[_c('v-text-field',{staticClass:"task-search",staticStyle:{"min-width":"400px"},attrs:{"slot":"action","placeholder":"Search for a task","dense":"","solo":"","flat":"","prepend-inner-icon":"search","hide-details":""},slot:"action",model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-card-text',[(!_vm.loading)?_c('v-data-table',{staticClass:"truncate-table",attrs:{"footer-props":{
        'items-per-page-options': [10, 15, 25, 50],
        'prev-icon': 'chevron_left',
        'next-icon': 'chevron_right'
      },"headers":_vm.headers,"header-props":{ 'sort-icon': 'arrow_drop_up' },"items":_vm.tasks,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"must-sort":"","page":_vm.page,"server-items-length":_vm.tasksCount,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('truncate',{attrs:{"content":item.name}},[_c('router-link',{staticClass:"link",attrs:{"data-cy":'task-link|' + item.name,"to":{ name: 'task', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"item.mapped",fn:function(ref){
      var item = ref.item;
return [(item.mapped)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" check ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.retry_delay",fn:function(ref){
      var item = ref.item;
return [(item.retry_delay)?_c('span',[_vm._v(_vm._s(_vm._f("duration")(item.retry_delay)))]):_c('span',[_vm._v(" - ")])]}},{key:"item.max_retries",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.max_retries)+" ")]}},{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("typeClass")(item.type))+" ")]}},{key:"item.trigger",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("typeClass")(item.trigger))+" ")]}}],null,false,3658072135)}):_c('div',[_c('v-skeleton-loader',{attrs:{"type":"table-row-divider"}}),_c('v-skeleton-loader',{attrs:{"type":"table-row"}}),_c('v-skeleton-loader',{attrs:{"type":"table-row"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }